import React, { useCallback, useEffect, useState } from 'react';
import { store } from '@mini-core/core';
import { Panel, Spinner, Placeholder, CellButton } from '@vkontakte/vkui';
import { debounce } from 'lodash';
import Icon28HelpOutline from '@vkontakte/icons/dist/28/help_outline';
import Header from '../Header';
import ShopBlock from '../../components/ShopBlock';
import BookCard from '../../components/BookCard';
import SearchResult from '../../components/SearchResult';
import FireEvent from '../../utils/FireEvent';
import * as API from '../../networking/api';
import './index.scss';

const Shop = ({ id, router }) => {
    const [shop, shopActions] = store.useModel('shop');
    const [searchValue, setSearchValue] = useState('');
    const [searchCounter, setSearchCounter] = useState(10);
    const [foundBooks, loadFoundBooks] = useState([]);
    const [loading, setLoading] = useState(false);
    const { books, categoryBooks } = shop;

    useEffect(() => {
        API.getBooks().then(books => {
            shopActions.loadBooks(books);
        });
    }, []);

    const searchHandler = useCallback(
        debounce(value => {
            loadFoundBooks([]);
            API.searchBooks(value, String(searchCounter))
                .then(data => {
                    if (data.result.success) {
                        setLoading(false);
                        loadFoundBooks(data.result.arts);
                    }
                })
                .catch(() => {
                    setLoading(false);
                    router.showAlert('Что-то пошло не так', 'Не удалось загрузить книги');
                });
        }, 2000),
        [],
    );

    const infiniteScrollLoader = () => {
        const getDocHeight = () => {
            const D = document;
            return Math.max(
                D.body.scrollHeight,
                D.documentElement.scrollHeight,
                D.body.offsetHeight,
                D.documentElement.offsetHeight,
                D.body.clientHeight,
                D.documentElement.clientHeight,
            );
        };

        const windowHeight = window.innerHeight || (document.documentElement || document.body).clientHeight;
        const docHeight = getDocHeight();
        const trackLength = docHeight - windowHeight;
        const scrollTop =
            window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

        if (trackLength === scrollTop) {
            setLoading(true);
            API.searchBooks(searchValue, String(searchCounter + 10))
                .then(data => {
                    if (data.result.success) {
                        setLoading(false);
                        if (data.result.arts.length === foundBooks.length) {
                            window.removeEventListener('scroll', infiniteScrollLoader);
                            return;
                        }
                        setSearchCounter(searchCounter + 10);
                        loadFoundBooks(data.result.arts);
                    }
                })
                .catch(() => {
                    setLoading(false);
                    router.showAlert('Что-то пошло не так', 'Не удалось загрузить книги');
                });
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', infiniteScrollLoader);
        return () => window.removeEventListener('scroll', infiniteScrollLoader);
    }, [searchCounter, searchValue]);

    const handleSupport = () => {
        FireEvent('https://vk.com/support?act=home_litres');
    };

    useEffect(() => {
        const id = setTimeout(() => {
            shopActions.resetCategoryBooks();
            clearTimeout(id);
        }, 300);

        return () => clearTimeout(id);
    }, []);

    return (
        <Panel id={id}>
            <Header
                showExtended
                router={router}
                activeTab="shop"
                setSearchValue={setSearchValue}
                setLoading={setLoading}
                searchHandler={searchHandler}
                searchValue={searchValue}
            />
            {!searchValue ? (
                <div className="shop">
                    <ShopBlock title="Популярные" type="r_genre_arts_pop" router={router}>
                        {books.popularBooks ? (
                            books.popularBooks
                                .slice(0, 5)
                                .map(book => <BookCard key={book.id} router={router} {...book} />)
                        ) : (
                            <Spinner />
                        )}
                    </ShopBlock>
                    <ShopBlock title="Последние" type="r_genre_arts_new" router={router}>
                        {books.newBooks ? (
                            books.newBooks.slice(0, 5).map(book => <BookCard key={book.id} router={router} {...book} />)
                        ) : (
                            <Spinner />
                        )}
                    </ShopBlock>
                    <ShopBlock title={'Выбор редактора'} type="editors-choice" router={router}>
                        {books.chosenBooks ? (
                            books.chosenBooks
                                .slice(0, 5)
                                .map(book => <BookCard key={book.id} router={router} {...book} />)
                        ) : (
                            <Spinner />
                        )}
                    </ShopBlock>
                </div>
            ) : (
                <SearchResult empty={foundBooks.length === 0 && !loading && searchValue.length > 0}>
                    {foundBooks.length > 0 &&
                        foundBooks.map(book => <BookCard key={book.id} router={router} {...book} />)}
                    {foundBooks.length === 0 && !loading && searchValue.length > 0 && (
                        <Placeholder className="cursor-default">Ничего не найдено</Placeholder>
                    )}
                    {loading && <Spinner />}
                </SearchResult>
            )}
            <CellButton before={<Icon28HelpOutline fill="#8FADC8" />} onClick={handleSupport}>
                Написать в поддержку
            </CellButton>
        </Panel>
    );
};

export default Shop;
