import { store } from '@mini-core/core';

const initialState = {
    books: [],
    categoryBooks: [],
    detailedBook: {},
    paymentsPrice: null,
    paymentStatus: null,
    showAllPanelTitle: 'Популярные',
    showAllContainerType: null,
    showAllTitle: [],
};

const actions = {
    loadBooks: books => {
        return {
            books,
        };
    },
    setCategoryBooks: books => {
        return {
            books,
        };
    },
    setDetailedBook: detailedBook => {
        return {
            detailedBook,
        };
    },
    setPaymentsPrice: paymentsPrice => {
        return {
            paymentsPrice,
        };
    },
    setShowAllTitle: showAllPanelTitle => {
        return {
            showAllPanelTitle,
        };
    },
    setShowAllContainerType: showAllContainerType => {
        return {
            showAllContainerType,
        };
    },
    resetCategoryBooks: () => {
        return {};
    },
    setPaymentsStatus: paymentStatus => {
        return {
            paymentStatus,
        };
    },
    pushShowAllTitle: nextTitle => {
        return {
            nextTitle,
        };
    },
    popShowAllTitle: () => {
        return {};
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'loadBooks':
            const { books } = action;
            return {
                ...state,
                books,
            };
        case 'setCategoryBooks':
            return {
                ...state,
                categoryBooks: [...state.categoryBooks, ...action.books],
            };
        case 'resetCategoryBooks':
            return {
                ...state,
                categoryBooks: [],
            };
        case 'setDetailedBook':
            const { detailedBook } = action;
            return {
                ...state,
                detailedBook,
            };
        case 'setPaymentsPrice':
            const { paymentsPrice } = action;
            return {
                ...state,
                paymentsPrice,
            };
        case 'setPaymentsStatus':
            const { paymentStatus } = action;
            return {
                ...state,
                paymentStatus,
            };
        case 'setShowAllTitle':
            const { showAllPanelTitle } = action;
            return {
                ...state,
                showAllPanelTitle,
            };
        case 'setShowAllContainerType':
            const { showAllContainerType } = action;
            return {
                ...state,
                showAllContainerType,
            };
        case 'pushShowAllTitle':
            const { nextTitle } = action;
            return {
                ...state,
                showAllTitle: [...state.showAllTitle, nextTitle],
            };
        case 'popShowAllTitle':
            const shallowCopy = [...state.showAllTitle];
            shallowCopy.pop();
            return {
                ...state,
                showAllTitle: shallowCopy,
            };
        default:
            return state;
    }
};

store.registerModel('shop', null, initialState, actions, reducer);
