import { store } from '@mini-core/core';

const initialState = {
    searchValue: null,
    isModalMaskDark: false,
};

const actions = {
    setSearchValue: searchValue => {
        return {
            searchValue,
        };
    },
    setIsModalMaskDark: isModalMaskDark => {
        return {
            isModalMaskDark,
        };
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setSearchValue':
            const { searchValue } = action;
            return {
                ...state,
                searchValue,
            };
        case 'setIsModalMaskDark':
            const { isModalMaskDark } = action;
            return {
                ...state,
                isModalMaskDark,
            };
        default:
            return state;
    }
};

store.registerModel('navigation', null, initialState, actions, reducer);
