import React, { useEffect, useState } from 'react';
import { Panel, Button } from '@vkontakte/vkui';
import { setVKStorage } from '../../networking/api';
import GA from 'react-ga';
import bridge from '@vkontakte/vk-bridge';
import litres_logo from '../../assets/litres_logo.svg';
import * as API from '../../networking/api';
import './index.scss';

const Login = ({ id, router }) => {
    const [isNextPage, setIsNextPage] = useState(false);

    const onLoginBtnClickHandler = () => {
        bridge
            .send('VKWebAppGetAuthToken', { app_id: 7371328, scope: '' })
            .then(data => {
                return API.getAuthUser(data.access_token);
            })
            .then(data => {
                if (data.success) {
                    router.openPage('shop');
                    setVKStorage('allow_token', '1');
                }
            });
    };

    useEffect(() => {
        GA.pageview('/login');
    }, []);

    return (
        <Panel id={id} centered>
            {!isNextPage && (
                <>
                    <img className="login__logo" src={litres_logo} alt="Логотип Литрес" />
                    <div className="login__text">
                        Покупайте и читайте любимые книги
                        <br /> не выходя из ВКонтакте
                    </div>
                    <Button mode="overlay-secondary" size="l" onClick={() => setIsNextPage(true)}>
                        Продолжить
                    </Button>
                </>
            )}
            {isNextPage && (
                <>
                    <img className="login__logo" src={litres_logo} alt="Логотип Литрес" />
                    <div className="login__text">
                        Авторизуйтесь через ВКонтакте, чтобы
                        <br />
                        мы могли показать вам те книги, которые
                        <br />
                        вы уже купили на сайте ЛитРес
                    </div>
                    <Button mode="overlay-secondary" size="l" onClick={onLoginBtnClickHandler}>
                        Авторизоваться через VK
                    </Button>
                </>
            )}
        </Panel>
    );
};

export default Login;
