import React from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Cell, ActionSheet, ActionSheetItem } from '@vkontakte/vkui';
import { setCurrentBookReader } from '../../store/reader/actions';
import FireEvent from '../../utils/FireEvent';
import * as API from '../../networking/api';
import './index.scss';

const LibraryBookCard = ({ id, title, type, readed_percent, persons, router }) => {
    const dispatch = useDispatch();

    const onReadBtnClickHandler = async () => {
        if (Number(type) === 4) {
            try {
                const result = await API.getReaderUrl(id);
                router.openPopout(
                    <ActionSheet onClose={() => router.openPopout(null)}>
                        {result.urls.map((head, pos) => {
                            return (
                                <ActionSheetItem
                                    autoclose
                                    onClick={() => FireEvent(`https://back.litres.juice.vk-apps.com${head}`)}
                                >
                                    Часть {pos + 1}
                                </ActionSheetItem>
                            );
                        })}
                        <ActionSheetItem autoclose mode="cancel">
                            Отменить
                        </ActionSheetItem>
                    </ActionSheet>,
                );
                return;
            } catch (e) {
                throw new Error('Ошибка'); // TODO обработка ошибки
            }
        }

        dispatch(setCurrentBookReader({ id, title }));
        router.openPanel('book-content');
    };

    return (
        <div className="library-book">
            <Cell
                size="l"
                before={<Avatar mode="image" src={`https://cv${id[id.length - 2]}.litres.ru/pub/c/cover/${id}.jpg`} />}
                description={
                    <div>
                        <span
                            className={
                                persons
                                    ? 'library-book__author'
                                    : 'library-book__author library-book__author--transparent'
                            }
                        >
                            {persons && persons[0].full_name}
                        </span>
                    </div>
                }
                bottomContent={<span onClick={onReadBtnClickHandler}>Читать</span>}
            >
                {title}
            </Cell>
        </div>
    );
};

export default LibraryBookCard;
