import 'core-js/features/map';
import 'core-js/features/set';
import React from 'react';
import ReactDOM from 'react-dom';
import GA from 'react-ga';
import bridge from '@vkontakte/vk-bridge';
import { utils, network } from '@mini-core/core';
import App from './containers/App';
import '@vkontakte/vkui/dist/vkui.css';
import './store/shop';
import './store/navigation';
import './store/reader';
import './store/reader-settings';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './store';

window.vk_settings = utils.parseParams(window.location.search);
bridge.subscribe(utils.schemeChanger);
const store = createStore(rootReducer);

GA.initialize('UA-171739682-1', {
    titleCase: false,
    gaOptions: {
        userId: window.vk_settings.vk_user_id || 0,
    },
});

network.init('https://back.litres.juice.vk-apps.com/', 'https://back.litres.juice.vk-apps.com/');

bridge.send('VKWebAppInit');

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
);
