import React from 'react';
import './index.scss';

const Genre = ({ title, handler = null }) => {
    return (
        <div className="genre" onClick={() => handler && handler()}>
            {title}
        </div>
    );
};

export default Genre;
