import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, Spinner, Placeholder } from '@vkontakte/vkui';
import { loadPurchasedBooks } from '../../store/reader/actions';
import Header from '../Header';
import LibraryBookCard from '../../components/LibraryBookCard';
import SearchResult from '../../components/SearchResult';
import * as API from '../../networking/api';
import './index.scss';

const Reader = ({ id, router }) => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchedBooks, setSearchedBooks] = useState([]);
    const purchasedBooks = useSelector(state => state.reader.purchasedBooks);

    useEffect(() => {
        setLoading(true);
        API.getUserBooks()
            .then(data => {
                setLoading(false);
                if (Object.keys(data).length === 0) {
                    dispatch(loadPurchasedBooks([]));
                } else {
                    dispatch(loadPurchasedBooks(data.myBooks));
                }
            })
            .finally(() => setLoading(false));
    }, []);

    const searchHandler = value => {
        setSearchValue(value);

        if (value.length >= 3) {
            setSearchedBooks(
                purchasedBooks.filter(
                    book =>
                        book.title.toLowerCase().includes(searchValue.toLowerCase()) ||
                        (book.persons && book.persons[0].full_name.toLowerCase().includes(searchValue.toLowerCase())),
                ),
            );
        }
    };

    return (
        <Panel id={id}>
            <Header
                showExtended
                router={router}
                activeTab="reader"
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                searchHandler={searchHandler}
            />
            <div className="reader">
                <div className="reader__books">
                    {searchValue.length >= 3 && (
                        <SearchResult empty={searchedBooks.length === 0}>
                            {searchedBooks.length > 0 ? (
                                searchedBooks.map(book => (
                                    <LibraryBookCard key={book.id} router={router} progress={100} {...book} />
                                ))
                            ) : (
                                <Placeholder className="cursor-default">По запросу ничего не найдено</Placeholder>
                            )}
                        </SearchResult>
                    )}
                    {purchasedBooks.length !== 0 &&
                        !loading &&
                        searchValue.length < 3 &&
                        purchasedBooks.map(book => (
                            <LibraryBookCard key={book.id} {...book} router={router} progress={100} />
                        ))}
                    {!loading && purchasedBooks.length === 0 && !searchValue.length && (
                        <Placeholder style={{ margin: '0 auto' }}>У вас нет купленных книг</Placeholder>
                    )}
                    {loading && <Spinner />}
                </div>
            </div>
        </Panel>
    );
};

export default Reader;
