import React from 'react';
import { store } from '@mini-core/core';
import Icon24BrowserForward from '@vkontakte/icons/dist/24/browser_forward';
import VkRetargetEvent from '../../utils/VkRetargetEvent';
import './index.scss';

const ShopBlock = ({ title, type, router, authorBooks = false, children }) => {
    const [shop, shopActions] = store.useModel('shop');

    const onShowAllClickHandler = title => {
        shopActions.setShowAllTitle(title);
        shopActions.setShowAllContainerType(type);
        shopActions.pushShowAllTitle(title);
        router.openPanel(!authorBooks ? 'show-all' : 'show-all-author');

        VkRetargetEvent('micro');
    };

    return (
        <div className="shop-block">
            <div className="shop-block__header">
                <span className="shop-block__title">{title}</span>
                <span className="shop-block__btn" onClick={() => onShowAllClickHandler(title)}>
                    Показать все
                    <Icon24BrowserForward width={8} height={8} fill="#9cb0c3" />
                </span>
            </div>
            <div className="shop-block__content">{children}</div>
        </div>
    );
};

export default ShopBlock;
