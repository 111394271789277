import React, { useEffect } from 'react';
import { Panel } from '@vkontakte/vkui';
import { getVKStorage } from '../../networking/api';
import bridge from '@vkontakte/vk-bridge';
import * as API from '../../networking/api';

const Empty = ({ id, router }) => {
    useEffect(() => {
        (async () => {
            const isAllowToken = await getVKStorage(['allow_token']);
            if (isAllowToken['allow_token'] && isAllowToken['allow_token'] === '1') {
                bridge
                    .send('VKWebAppGetAuthToken', { app_id: 7371328, scope: '' })
                    .then(data => {
                        return API.getAuthUser(data.access_token);
                    })
                    .then(data => {
                        if (data.success) {
                            router.openPage('shop');
                        } else {
                            router.openPage('login');
                        }
                    })
                    .catch(() => {
                        router.openPage('login');
                    });
            } else {
                router.openPage('login');
            }
        })();
    }, []);

    return <Panel id={id} />;
};

export default Empty;
