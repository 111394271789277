import React, { useEffect, useState } from 'react';
import { store, utils } from '@mini-core/core';
import { Group, Panel, PanelHeader, Spinner, Div } from '@vkontakte/vkui';
import Icon24FavoriteOutline from '@vkontakte/icons/dist/24/favorite_outline';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import Header from '../Header';
import Genre from '../../components/GenreBlock';
import ShopBlock from '../../components/ShopBlock';
import BookCard from '../../components/BookCard';
import ParsePrice from '../../utils/ParsePrice';
import VkRetargetEvent from '../../utils/VkRetargetEvent';
import * as API from '../../networking/api';
import './index.scss';

const BookDetails = ({ id, isBought, router }) => {
    const [shop, shopActions] = store.useModel('shop');
    const {
        ll_avg_marks,
        base_price,
        annotation,
        final_price,
        title,
        chars,
        persons,
        minage,
        year_written,
        genres,
        my,
    } = shop.detailedBook;

    const [fullAnno, setFullAnno] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imgLoading, setImgLoading] = useState(true);
    const [authorBooks, setAuthorBooks] = useState([]);
    const [genreCounter, setGenreCounter] = useState(7);

    const openPayments = () => {
        VkRetargetEvent('click_pay');

        shopActions.setPaymentsPrice(
            Number(base_price) !== Number(final_price) ? ParsePrice(final_price) : ParsePrice(base_price),
        );
        router.openModal('payments');
    };

    useEffect(() => {
        setFullAnno(false);
    }, [shop.detailedBook]);

    useEffect(() => {
        if (persons && persons[0].full_name) {
            setLoading(true);
            API.searchBooks(persons[0].full_name, '10')
                .then(data => {
                    if (data.result.success) {
                        setLoading(false);
                        setAuthorBooks(data.result.arts.slice(0, 5));
                    }
                })
                .catch(() => {
                    setLoading(false);
                    router.showAlert('Что-то пошло не так', 'Не удалось загрузить книги');
                });
        }
    }, []);

    const mapAnnotation = () => {
        return annotation
            .split('</p>')
            .map((p, i) => p.trim().slice(3, p.length))
            .join(' ');
    };

    return (
        <Panel id={id} separator={false}>
            <section className="book-details">
                <Header />
                <PanelHeader
                    left={
                        <div className="book-details__header" onClick={() => router.goBack()}>
                            <Icon24BrowserBack width={20} height={24} fill="#a2a9b5" />
                            Назад
                        </div>
                    }
                >
                    <div className="header__logo">
                        <span className="header__subtitle">
                            Лит
                            <span className="header__subtitle header__subtitle--orange">Рес:</span>
                        </span>
                    </div>
                </PanelHeader>
                <Group>
                    <div className="book-details__card">
                        {imgLoading && <Spinner className="img-loader" />}
                        <img
                            src={`https://cv${id[id.length - 2]}.litres.ru/pub/c/cover/${shop.detailedBook.id}.jpg`}
                            alt=""
                            width={130}
                            height={191}
                            className={imgLoading ? 'book-details__cover--hide' : ''}
                            onLoad={e => setImgLoading(false)}
                        />
                        <div className="book-details__card-content">
                            <span className="book-details__card-title">{title}</span>
                            <span className="book-details__card-author">{persons && persons[0].full_name}</span>
                            {ll_avg_marks && (
                                <span className="book-details__card-rating">
                                    {Number(ll_avg_marks).toPrecision(3)}
                                    <Icon24FavoriteOutline fill="#76787a" width={16} height={16} />
                                </span>
                            )}
                            {annotation && !fullAnno && (
                                <p className="book-details__card-desc">
                                    {`${mapAnnotation().slice(0, 100)}...`}
                                    <span className="book-details__card-show-more" onClick={() => setFullAnno(true)}>
                                        Показать полностью...
                                    </span>
                                </p>
                            )}
                            {annotation && fullAnno && (
                                <p
                                    className="book-details__card-desc"
                                    dangerouslySetInnerHTML={{ __html: annotation }}
                                />
                            )}
                            {my && Number(my) === 1 && (
                                <div
                                    className="book-details__btn book-details__btn-read"
                                    onClick={() => router.openPage('reader')}
                                >
                                    Читать
                                </div>
                            )}
                            {my === undefined && (
                                <div className="book-details__btn book-details__btn-buy" onClick={openPayments}>
                                    {Number(base_price) !== Number(final_price) ? (
                                        <>
                                            <div>Купить {ParsePrice(Number(final_price))} ₽</div>
                                            <div className="book-details__btn-buy--crossed">
                                                {ParsePrice(Number(base_price))} ₽
                                            </div>
                                        </>
                                    ) : (
                                        `Купить ${ParsePrice(Number(base_price))} ₽`
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </Group>
                <Group>
                    <div className="book-details__info">
                        <div className="book-details__info-title">О книге</div>
                        {minage && <span>Возрастное ограничение: {minage}+</span>}
                        {year_written && <span>Дата написания: {year_written}</span>}
                        {chars && (
                            <span>
                                Объем: {Math.floor(chars / 1595)}{' '}
                                {utils.wordPad(Math.floor(chars / 1595), 'страница', 'страницы', 'страниц')}
                            </span>
                        )}
                        <div className="book-details__genres">
                            {genres &&
                                genres.slice(0, genreCounter).map(genre => <Genre key={genre.id} title={genre.name} />)}
                            {genres.length > 7 && genreCounter !== genres.length && (
                                <div className="book-details__genres-more">
                                    <Genre title="..." handler={() => setGenreCounter(genres.length)} />
                                </div>
                            )}
                        </div>
                    </div>
                </Group>
                {!loading && authorBooks.length > 1 && (
                    <Group>
                        <ShopBlock title={'Другие книги автора'} router={router} authorBooks>
                            {!loading &&
                                authorBooks.length > 0 &&
                                authorBooks
                                    .filter(book => book.id !== shop.detailedBook.id)
                                    .map(book => (
                                        <BookCard
                                            setImgLoading={setImgLoading}
                                            push={false}
                                            router={router}
                                            key={book.id}
                                            {...book}
                                        />
                                    ))}
                        </ShopBlock>
                    </Group>
                )}
                {loading && <Spinner />}
                {!loading && authorBooks.length === 1 && (
                    <Div className="cursor-default" style={{ paddingLeft: '25px' }}>
                        У автора нет других книг
                    </Div>
                )}
            </section>
        </Panel>
    );
};

BookDetails.defaultProps = {
    isBought: Math.random() > 0.5,
};

export default BookDetails;
