import React, { useState, useEffect } from 'react';
import { ModalCard, FormLayout, Radio, Button } from '@vkontakte/vkui';
import { store } from '@mini-core/core';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';
import VkRetargetEvent from '../../utils/VkRetargetEvent';
import bridge from '@vkontakte/vk-bridge';
import GA from 'react-ga';
import * as API from '../../networking/api';
import './index.scss';
import { sendStat } from '../../networking/api';

const Payments = ({ id, router }) => {
    const [loading, setLoading] = useState(false);
    const [shop, shopActions] = store.useModel('shop');

    const buyHandler = async () => {
        GA.event({
            category: 'User',
            action: 'click-buy-btn',
            label: 'payment-modal',
        });

        setLoading(true);

        const paymentInfo = await API.getPaymentInfo(shop.detailedBook.id);

        bridge
            .send('VKWebAppOpenPayForm', {
                app_id: 7371328,
                action: 'pay-to-service',
                params: {
                    amount: paymentInfo.paymentInfo.amount,
                    data: { ...paymentInfo.paymentInfo.data },
                    description: paymentInfo.paymentInfo.description,
                    merchant_id: paymentInfo.paymentInfo.merchant_id,
                    sign: paymentInfo.paymentInfo.sign,
                },
            })
            .then(data => {
                if (data.status) {
                    return API.buyBook(shop.detailedBook.id, paymentInfo.litresOrderID);
                }
            })
            .then(async data => {
                if (data.success) {
                    setLoading(false);
                    shopActions.setPaymentsStatus(true);
                    router.openModal('payments-status');

                    VkRetargetEvent('purchase');

                    const events = JSON.stringify([
                        {
                            json: JSON.stringify({ book_id: shop.detailedBook.id }),
                            event: 'book_purchase',
                            user_id: Number(window.vk_settings.vk_user_id),
                            mini_app_id: Number(window.vk_settings.vk_app_id),
                            url: window.location.href,
                            vk_platform: window.vk_settings.vk_platform,
                            type_click: {
                                type: 'type_mini_app_custom_event_item',
                            },
                        },
                    ]);

                    sendStat(events);
                }
            })
            .catch(e => {
                setLoading(false);
                shopActions.setPaymentsStatus(false);
                router.openModal('payments-status');
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        GA.modalview('/payment-modal');
    }, []);

    return (
        <ModalCard id={id} className="modal-custom" onClose={() => router.goBack()}>
            <div className="payments__header">
                Способ оплаты
                <Icon16Cancel fill="#b0bcca" onClick={() => router.goBack()} />
            </div>
            <FormLayout>
                <div>
                    <Radio name="radio" value="1" defaultChecked>
                        VkPay
                    </Radio>
                </div>
            </FormLayout>
            <div className="payments__summary">
                <span>
                    ИТОГО: &nbsp;
                    <span className="payments__price">{shop.paymentsPrice} ₽</span>
                </span>
                <Button onClick={buyHandler}>Оплатить</Button>
            </div>
        </ModalCard>
    );
};

export default Payments;
