import * as types from './actionsTypes';

export const loadPurchasedBooks = purchasedBooks => ({
    type: types.READER_LOAD_PURCHASED_BOOKS,
    purchasedBooks,
});

export const setCurrentBookReader = currentBook => ({
    type: types.READER_SET_CURRENT_BOOK,
    currentBook,
});

export const setReaderUrl = readerUrl => {
    return {
        type: types.READER_SET_READER_URL,
        readerUrl,
    };
};
