import React from 'react';
import { router, store } from '@mini-core/core';
import Shop from '../Shop';
import BookDetails from '../BookDetails';
import Reader from '../Reader';
import ShowAll from '../ShowAllPanel';
import BookContent from '../BookContent';
import Login from '../Login';
import PaymentsStatus from '../PaymentsStatus';
import Payments from '../Payments';
import ShowAllAuthor from '../ShowAllAuthor';
import Empty from '../Empty';
import './index.scss';

const App = () => {
    const [navigation, navigationActions] = store.useModel('navigation');

    return (
        <div className={navigation.isModalMaskDark ? 'app modal-dark' : 'app'}>
            <router.Router
                initialPage="empty"
                modals={[<Payments id="payments" />, <PaymentsStatus id="payments-status" />]}
            >
                <router.Page id="empty" initialPanel="empty">
                    <Empty id="empty" />
                </router.Page>
                <router.Page id="login" initialPanel="login">
                    <Login id="login" />
                </router.Page>
                <router.Page id="shop" initialPanel="shop">
                    <Shop id="shop" />
                    <ShowAll id="show-all" />
                    <BookDetails id="book-details" />
                    <ShowAllAuthor id="show-all-author" />
                </router.Page>
                <router.Page id="reader" initialPanel="reader">
                    <Reader id="reader" />
                    <BookContent id="book-content" />
                </router.Page>
            </router.Router>
        </div>
    );
};

export default App;
