import React from 'react';
import { store } from '@mini-core/core';
import { ModalCard } from '@vkontakte/vkui';
import Icon56CheckCircleOutline from '@vkontakte/icons/dist/56/check_circle_outline';
import Icon28CancelCircleOutline from '@vkontakte/icons/dist/28/cancel_circle_outline';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';
import './index.scss';

const PaymentsStatus = ({ id, router }) => {
    const [shop] = store.useModel('shop');

    const onClose = () => {
        router.openModal(null);
    };

    const onReadButtonClickHandler = () => {
        onClose();
        onClose();
        router.openPage('reader');
    };

    return (
        <ModalCard
            id={id}
            onClose={onClose}
            icon={
                !shop.paymentStatus ? (
                    <Icon28CancelCircleOutline width={56} height={56} fill="#eb4250" />
                ) : (
                    <Icon56CheckCircleOutline />
                )
            }
            header={!shop.paymentStatus ? 'Ошибка' : 'Оплачено'}
            caption={!shop.paymentStatus ? 'Оплата не прошла' : null}
            actions={
                !shop.paymentStatus
                    ? [
                          {
                              title: 'Повторить',
                              mode: 'secondary',
                              action: () => router.openModal('payments'),
                          },
                      ]
                    : [
                          {
                              title: 'Читать',
                              mode: 'primary',
                              action: onReadButtonClickHandler,
                          },
                      ]
            }
        >
            <div className="payments-status__header">
                <Icon16Cancel fill="#b0bcca" onClick={onClose} />
            </div>
        </ModalCard>
    );
};

PaymentsStatus.defaultProps = {
    isError: true,
};

export default PaymentsStatus;
