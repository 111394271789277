import { network } from '@mini-core/core';
import bridge from '@vkontakte/vk-bridge';

const token =
    'dmtfYWNjZXNzX3Rva2VuX3NldHRpbmdzPSZ2a19hcHBfaWQ9NzM3MTMyOCZ2a19hcmVfbm90aWZpY2F0aW9uc19lbmFibGVkPTAmdmtfaXNfYXBwX3VzZXI9MSZ2a19pc19mYXZvcml0ZT0wJnZrX2xhbmd1YWdlPXJ1JnZrX3BsYXRmb3JtPWRlc2t0b3Bfd2ViJnZrX3JlZj1vdGhlciZ2a191c2VyX2lkPTE4MjYyNTc4NiZzaWduPXkzaC1zVDFLVDJZeXlScG5DLXZaUWRLZUZkaDFQSkxQaUg0T25tMW5hYnM=';

const generateCoverURL = id => {
    const server = String(id);
    return `https://cv${server[id.length - 2]}.litres.ru/pub/c/cover_200/${id}.jpg`;
};

const parseBook = book => ({
    id: book.id,
    title: book.title,
    author: book.publisher,
    price: Number(book.base_price),
    cover: generateCoverURL(book.id),
    description: '',
    rating: Number(book.lvl),
    genres: book.genres.map(genre => genre.name),
    discount: {
        status: 0,
        amount: 0,
    },
    ageRestriction: `${book.minage}+`,
    size: Number(book.chars),
    dateOfWriting: Number(book.year_written),
});

export const getBooks1 = () =>
    new Promise(resolve => {
        network
            .call('getBooks', {
                app_token: token,
            })
            .then(response => {
                resolve({
                    popular: response.popular.map(parseBook),
                    latest: response.latest.map(parseBook),
                });
            });
    });

export const getBooks = () => network.call('getCachedBooks');
export const getBooksTest = (method, count, offset) => network.call('getBooks', { method, count, offset });
export const getAuthUser = token => network.call('authorize', { token });
export const searchBooks = (q, limitMax = 5) => network.call('search', { q, limitMax });
export const getUserBooks = () => network.call('getMyBooks');
export const getReaderUrl = id => network.call('getReaderUrl', { id });
export const getPaymentInfo = id => network.call('getPaymentInfo', { id });
export const buyBook = (bookID, orderID) => network.call('buyBook', { bookID, orderID });
export const sendStat = events => network.call('sendStat', { eve: events });

export const getVKStorage = keys =>
    new Promise(resolve => {
        bridge
            .send('VKWebAppStorageGet', {
                keys,
            })
            .then(response => {
                const compiled = response.keys.reduce((a, x) => {
                    a[x.key] = x.value;
                    return a;
                }, {});
                resolve(compiled);
            })
            .catch(error => {
                resolve({});
            });
    });

export const setVKStorage = (key, value) =>
    new Promise(resolve => {
        bridge
            .send('VKWebAppStorageSet', {
                key,
                value,
            })
            .then(() => resolve())
            .catch(() => resolve());
    });
