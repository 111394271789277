import React, { useCallback, useEffect, useState } from 'react';
import { store } from '@mini-core/core';
import { List, Panel, PanelHeader, Spinner, Placeholder } from '@vkontakte/vkui';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import BookCard from '../../components/BookCard';
import * as API from '../../networking/api';
import '../ShowAllPanel/index.scss';

const ShowAllAuthor = ({ id, router }) => {
    const [searchValue, setSearchValue] = useState('');
    const [foundBooks, loadFoundBooks] = useState([]);
    const [filteredBooks, setFilteredBooks] = useState(null);
    const [loading, setLoading] = useState(false);
    const [shop, shopActions] = store.useModel('shop');

    const searchHandler = useCallback(
        value => {
            setFilteredBooks(foundBooks.filter(book => book.title.toLowerCase().includes(value.toLowerCase())));
        },
        [foundBooks],
    );

    useEffect(() => {
        setLoading(true);
        API.searchBooks(shop.detailedBook.persons[0].full_name, '999')
            .then(data => {
                if (data.result.success) {
                    setLoading(false);
                    loadFoundBooks(data.result.arts);
                }
            })
            .catch(() => {
                setLoading(false);
                router.showAlert('Что-то пошло не так', 'Не удалось загрузить книги');
            });
    }, []);

    const stepBack = () => {
        shopActions.popShowAllTitle();
        router.goBack();
    };

    return (
        <Panel id={id}>
            <PanelHeader
                left={
                    <div className="show-all__header" onClick={() => stepBack()}>
                        <Icon24BrowserBack width={20} height={24} fill="#707d8c" />
                        Назад
                    </div>
                }
            >
                Другие книги автора
            </PanelHeader>
            <List>
                <div className="show-all__books">
                    {loading && <Spinner />}
                    {foundBooks ? (
                        foundBooks.map(book => <BookCard router={router} key={book.id} {...book} />)
                    ) : (
                        <Placeholder className="cursor-default" style={{ marginLeft: 12 }}>
                            Ничего не найдено
                        </Placeholder>
                    )}
                </div>
            </List>
        </Panel>
    );
};

export default ShowAllAuthor;
