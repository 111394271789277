import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, ScreenSpinner } from '@vkontakte/vkui';
import { setReaderUrl } from '../../store/reader/actions';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import Header from '../Header';
import * as API from '../../networking/api';
import './index.scss';

const BookContent = ({ id, router }) => {
    const dispatch = useDispatch();
    const currentBook = useSelector(store => store.reader.currentBook);
    const readerUrl = useSelector(store => store.reader.readerUrl);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (currentBook) {
            /*setLoading(true);*/
            API.getReaderUrl(currentBook.id)
                .then(data => {
                    dispatch(setReaderUrl(data.url));
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, []);

    return (
        <Panel id={id}>
            <section className="book-content">
                <Header />
                <div className="book-content__header">
                    <div className="book-content__header-left" onClick={() => router.goBack()}>
                        <Icon24BrowserBack width={20} height={24} fill="#707d8c" />
                        Назад
                    </div>
                    <div className="book-content__header-center">{currentBook.title}</div>
                </div>
                <div className="book-content__main">
                    {readerUrl && <iframe src={`https://back.litres.juice.vk-apps.com${readerUrl}`} />}
                </div>
                {loading && <ScreenSpinner />}
            </section>
        </Panel>
    );
};

BookContent.defaultProps = {
    mainPage: false,
};

export default BookContent;
