import React, { useState } from 'react';
import { Search } from '@vkontakte/vkui';
import Icon24Search from '@vkontakte/icons/dist/24/search';
import logo from '../../assets/litres-ru-logo.svg';
import './index.scss';

const Header = ({ showExtended, router, activeTab, setLoading, setSearchValue, searchValue, searchHandler }) => {
    const [searchInput, setSearchInput] = useState(false);

    const changeView = view => {
        router.openModal(null);
        router.openPage(view);
    };

    const blurHandler = () => {
        searchValue.length === 0 && setSearchInput(false);
    };

    return (
        <header className="header">
            {showExtended && (
                <div className={!searchInput ? 'header__extended' : 'header__extended header__extended--selected'}>
                    {!searchInput && (
                        <div className="header__logo">
                            <img src={logo} alt="" />
                        </div>
                    )}
                    {!searchInput && (
                        <div className="header__nav">
                            <span
                                className={`header__nav-btn header__nav-btn-shop ${
                                    activeTab === 'shop' ? 'selected' : ''
                                }`}
                                onClick={() => changeView('shop')}
                            >
                                Магазин
                            </span>
                            <span
                                className={`header__nav-btn header__nav-btn-reader ${
                                    activeTab === 'reader' ? 'selected' : ''
                                }`}
                                onClick={() => changeView('reader')}
                            >
                                Мои книги
                            </span>
                        </div>
                    )}
                    <div className={searchInput ? 'header__search header__search--selected' : 'header__search'}>
                        {!searchInput && <Icon24Search fill="#b0bcca" onClick={() => setSearchInput(true)} />}
                        {searchInput && (
                            <Search
                                value={searchValue}
                                getRef={e => e && e.focus()}
                                onBlur={blurHandler}
                                onChange={e => {
                                    setLoading && setLoading(true);
                                    setSearchValue(e.target.value);
                                    searchHandler(e.target.value);
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
        </header>
    );
};

Header.defaultProps = {
    showExtended: false,
};

export default Header;
