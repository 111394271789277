import React, { useEffect, useState } from 'react';
import { store } from '@mini-core/core';
import { Panel, PanelHeader, List, Spinner } from '@vkontakte/vkui';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import BookCard from '../../components/BookCard';
import SearchResult from '../../components/SearchResult';
import GA from 'react-ga';
import * as API from '../../networking/api';
import './index.scss';

const ShowAll = ({ id, router }) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchCounter, setSearchCounter] = useState(60);
    const [foundBooks, setFoundBooks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [shop, shopActions] = store.useModel('shop');

    const { showAllPanelTitle, showAllContainerType, categoryBooks } = shop;

    const chooseRightCategory = () => {
        switch (shop.showAllTitle[shop.showAllTitle.length - 1]) {
            case 'Популярные':
                return 'popularBooks';
            case 'Последние':
                return 'newBooks';
            default:
                return 'chosenBooks';
        }
    };

    const category = chooseRightCategory();

    /*const searchHandler = useCallback(debounce((value) => {
    setLoading(false);
    setFoundBooks(shop.books[category]
      .filter((book) => book.title.toLowerCase().includes(value.toLowerCase()) ||
        book.persons[0].full_name.toLowerCase().includes(value.toLowerCase())));
  }, 2000), []);*/

    useEffect(() => {
        showAllContainerType && GA.pageview(`shop/${showAllContainerType}`);
    }, []);

    const trackButBtnHandler = () => {
        GA.event({
            category: 'User',
            action: 'click-buy-btn',
            label: `shop/${showAllContainerType}`,
        });
    };

    const stepBack = () => {
        shopActions.popShowAllTitle();
        router.goBack();
    };

    const infiniteScrollLoader = () => {
        const getDocHeight = () => {
            const D = document;
            return Math.max(
                D.body.scrollHeight,
                D.documentElement.scrollHeight,
                D.body.offsetHeight,
                D.documentElement.offsetHeight,
                D.body.clientHeight,
                D.documentElement.clientHeight,
            );
        };

        const windowHeight = window.innerHeight || (document.documentElement || document.body).clientHeight;
        const docHeight = getDocHeight();
        const trackLength = docHeight - windowHeight;
        const scrollTop =
            window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

        if (trackLength - 150 <= scrollTop && !loading) {
            setLoading(true);
            API.getBooksTest(showAllContainerType, 60, searchCounter + 60)
                .then(data => {
                    setSearchCounter(prev => prev + 60);
                    shopActions.setCategoryBooks(data.books);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        shopActions.resetCategoryBooks();

        return () => {
            shopActions.resetCategoryBooks();
            shopActions.setShowAllContainerType(null);
        };
    }, []);

    useEffect(() => {
        showAllContainerType && GA.pageview(`shop/${showAllContainerType}`);
    }, []);

    useEffect(() => {
        if (showAllContainerType) {
            if (showAllContainerType !== 'r_genre_arts_pop' && showAllContainerType !== 'r_genre_arts_new') {
                shopActions.setCategoryBooks(shop.books['chosenBooks']);
                setLoading(false);
                return;
            }

            setLoading(true);

            API.getBooksTest(showAllContainerType).then(d => {
                shopActions.setCategoryBooks(d.books);
                setLoading(false);
            });
        }
    }, [showAllContainerType]);

    useEffect(() => {
        if (
            showAllContainerType &&
            (showAllContainerType === 'r_genre_arts_pop' || showAllContainerType === 'r_genre_arts_new')
        ) {
            window.addEventListener('scroll', infiniteScrollLoader);
        }

        return () => {
            window.removeEventListener('scroll', infiniteScrollLoader);
        };
    }, [searchCounter, loading]);

    return (
        <Panel id={id}>
            <PanelHeader
                left={
                    <div className="show-all__header" onClick={() => stepBack()}>
                        <Icon24BrowserBack width={20} height={24} fill="#707d8c" />
                        Назад
                    </div>
                }
            >
                {shop.showAllTitle[shop.showAllTitle.length - 1]}
            </PanelHeader>
            {!searchValue ? (
                <List>
                    <div className="show-all__books">
                        {categoryBooks &&
                            categoryBooks.map(book => <BookCard router={router} key={book.id} {...book} />)}
                    </div>
                </List>
            ) : (
                <SearchResult empty={foundBooks.length === 0 && !loading}>
                    {loading && <Spinner />}
                    {shop.showAllPanelTitle !== '' && shop.books[category] && foundBooks.length > 0
                        ? foundBooks.map(book => <BookCard router={router} key={book.id} {...book} />)
                        : !loading && (
                              <div className="cursor-default" style={{ marginLeft: 12 }}>
                                  Ничего не найдено
                              </div>
                          )}
                </SearchResult>
            )}
            {loading && <Spinner style={{ marginBottom: 10 }} />}
        </Panel>
    );
};

export default ShowAll;
