import * as types from './actionsTypes';

const initialState = {
    purchasedBooks: [],
    currentBook: null,
    readerUrl: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.READER_LOAD_PURCHASED_BOOKS:
            const { purchasedBooks } = action;
            return {
                ...state,
                purchasedBooks,
            };
        case types.READER_SET_CURRENT_BOOK:
            const { currentBook } = action;
            return {
                ...state,
                currentBook,
            };
        case types.READER_SET_READER_URL:
            const { readerUrl } = action;
            return {
                ...state,
                readerUrl,
            };
        default:
            return state;
    }
};

export default reducer;
