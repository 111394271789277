import React from 'react';
import { store } from '@mini-core/core';
import { calculateDiscount } from '../../utils/CalculateDiscount';
import ParsePrice from '../../utils/ParsePrice';
import './index.scss';

const BookCard = props => {
    const [shop, shopActions] = store.useModel('shop');
    const { id, base_price, final_price, title, my, persons, router, push = true, setImgLoading = () => {} } = props;

    const openBookDetails = () => {
        shopActions.setDetailedBook({ ...props });
        setImgLoading(true);
        if (push) {
            router.openPanel('book-details');
        }
    };

    return (
        <div className="book-card">
            <img
                className="book-card__cover"
                src={`https://cv${id[id.length - 2]}.litres.ru/pub/c/cover/${id}.jpg`}
                alt=""
                onClick={openBookDetails}
            />
            <span className="book-card__title">{title}</span>
            <span className="book-card__author">{persons && persons[0].full_name}</span>
            <div className="book-card__price">
                {!my ? (
                    Number(base_price) !== Number(final_price) ? (
                        <span className="book-card__price-discount">
                            {Number(base_price) !== Number(final_price) && ParsePrice(Number(final_price))} ₽
                            <span className="book-card__price-full">{ParsePrice(Number(base_price))} ₽</span>
                        </span>
                    ) : (
                        <span className="book-card__price-full">{ParsePrice(Number(base_price))} ₽</span>
                    )
                ) : null}
                {my && Number(my) === 1 && <span className="book-card__price-full">Куплено</span>}
            </div>
            {!my && Number(base_price) !== Number(final_price) ? (
                <div className="book-card__discount">-{calculateDiscount(base_price, final_price)}%</div>
            ) : null}
        </div>
    );
};

export default BookCard;
