import React from 'react';
import './index.scss';

const SearchResult = props => {
    return (
        <div className="search-result">
            <div className="search-result__title cursor-default">Результаты поиска</div>
            <div className={`search-result__content ${props.empty ? 'search-result__content--empty' : ''}`}>
                {props.children}
            </div>
        </div>
    );
};

export default SearchResult;
