import { store } from '@mini-core/core';
import * as API from '../../networking/api';

const initialState = {
    activeTheme: 9,
    activeFont: 9,
    activeSize: 0,
};

const actions = {
    setTheme: activeTheme => {
        API.setVKStorage('rs_theme', String(activeTheme));
        return {
            activeTheme,
        };
    },
    setFont: activeFont => {
        API.setVKStorage('rs_font_family', String(activeFont));
        return {
            activeFont,
        };
    },
    setSize: activeSize => {
        API.setVKStorage('rs_font_size', String(activeSize));
        return {
            activeSize,
        };
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setTheme': {
            const { activeTheme } = action;
            return {
                ...state,
                activeTheme,
            };
        }
        case 'setFont': {
            const { activeFont } = action;
            return {
                ...state,
                activeFont,
            };
        }
        case 'setSize': {
            const { activeSize } = action;
            return {
                ...state,
                activeSize,
            };
        }
        default:
            return state;
    }
};

store.registerModel('reader-settings', null, initialState, actions, reducer);
